export const PRODUCTS_TAB = 0
export const INDUSTRY_APPLICATIONS_TAB = 1
export const REGULATORY_TAB = 2
export const RESOURCES_TAB = 3
// export const NEWS_TAB = 4
export const ALL_RESULTS_TAB = 4
export const WEBCONTENT_FUNCTION = 5
export const LOAD_MORE_WEB_CONTENT = 20
export const LOAD_MORE_DATA = 51
export const LOAD_MORE_DATA_MOBILE = 51
