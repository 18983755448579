import React, { useContext, useEffect, useState, useRef } from 'react'
import { Translations } from '../services/TranslationService.jsx'
import { StateContext } from '../services/StateService.jsx'
import { getSerpTab, trackScroll } from '../services/BrowserService.jsx'
import ResultsTitle from '../components/ResultsTitle.jsx'
import ProductsView from '../fragments/serp/ProductsView.jsx'
import WebContentView from '../fragments/serp/WebContentView.jsx'
import RegulatoryView from '../fragments/serp/RegulatoryView.jsx'
import ResourcesView from '../fragments/serp/ResourcesView.jsx'
import AllResultsView from '../fragments/serp/AllResultsView.jsx'
import NoResultsView from '../fragments/serp/NoResultsView.jsx'
import Tabs from '@3mcom/mds-library/dist/node/Tabs'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { tabController, updateTabsTotal } from '../services/SERPService.jsx'
import { wrapInParenthesis } from '../services/UtilityService.jsx'
import { trackSERPOnloadData, getTealiumObj } from '../services/TealiumService.jsx'
import {
    PRODUCTS_TAB,
    INDUSTRY_APPLICATIONS_TAB,
    REGULATORY_TAB,
    RESOURCES_TAB,
    NEWS_TAB,
    ALL_RESULTS_TAB
} from '../constants/constants.js'
import '../css/SERP.scss'
import BreadCrumbList from '../fragments/BreadCrumbList.jsx'

/* istanbul ignore next */
function SERP() {
    // Example of how to modify the state from component using the dispact fn.
    const { capProducts, industryApplications, regulatory, resources, allResults } =
        useContext(Translations)
    const {
        breadCrumb,
        aggregations,
        breadBox,
        canonicalUrl,
        dispatch,
        endpoints,
        greater,
        webContentData,
        items,
        primaryPageTitle,
        query,
        queryId,
        regulatoryData,
        resourcesData,
        total = 0,
        loading,
        brandedSite
    } = useContext(StateContext)
    const tabsRef = useRef()

    // Create trackScroll hook based on browser tab history
    trackScroll()

    const { setTab } = tabController({
        dispatch,
        endpoints,
        webContentData,
        regulatoryData,
        resourcesData,
        items
    })

    const [tabCountLoaded, setTabCountLoaded] = useState(false)
    const [totalResults, setTotalResults] = useState()
    const [productsTotal] = useState(total)
    const [webContentTotal, setWebContentTotal] = useState()
    const [regulatoryTotal, setRegulatoryTotal] = useState()
    const [resourcesTotal, setResourcesTotal] = useState()
    // all tab data
    const [allTabData, setAllTabData] = useState({
        items: null,
        webContent: null,
        regulatory: null,
        resources: null
    })

    const prevTab = getSerpTab()

    useEffect(() => {
        // This will load the data for the tab's total after render of this page.
        updateTabsTotal(endpoints, dispatch, setTabCountLoaded)

        // If window history state tracked tab element, trigger tab events
        if (prevTab) {
            tabsRef.current.jumpTo(prevTab)
        }
    }, [])

    // let's fetch the initial amount of items in each tab
    useEffect(() => {
        if (tabCountLoaded) {
            const webContentDataTotal = webContentData?.total ?? 0
            const regulatoryDataTotal = regulatoryData?.total ?? 0
            const resourcesDataTotal = resourcesData?.total ?? 0
            setWebContentTotal(webContentDataTotal)
            setRegulatoryTotal(regulatoryDataTotal)
            setResourcesTotal(resourcesDataTotal)
            const sumTotal =
                total + webContentDataTotal + regulatoryDataTotal + resourcesDataTotal
            setTotalResults(sumTotal)
            trackSERPOnloadData(total, totalResults, greater, query)

            // Jump to next tab if the products tab has 0 results
            if (!(total > 0) && sumTotal > 0 && !prevTab) {
                const skipToTab =
                    webContentDataTotal > 0
                        ? INDUSTRY_APPLICATIONS_TAB
                        : regulatoryDataTotal > 0
                        ? REGULATORY_TAB
                        : resourcesDataTotal > 0
                        ? RESOURCES_TAB
                        : ALL_RESULTS_TAB
                tabsRef.current.jumpTo(skipToTab)
                setTab(skipToTab)
            }
        }
    }, [tabCountLoaded])

    // let's check if the initial data has already arrived
    // for the all tab information.
    useEffect(() => {
        if (!allTabData.items && items.length > 0) {
            setAllTabData({
                ...allTabData,
                items: items
            })
        } else if (!allTabData.webContent && webContentData?.items) {
            setAllTabData({
                ...allTabData,
                webContent: webContentData.items
            })
        } else if (!allTabData.resources && resourcesData?.mediaLinks) {
            setAllTabData({
                ...allTabData,
                resources: resourcesData.mediaLinks
            })
        } else if (!allTabData.regulatory && regulatoryData?.items) {
            setAllTabData({
                ...allTabData,
                regulatory: regulatoryData.items
            })
        }
    }, [items, webContentData, resourcesData, regulatoryData])

    // Return 0 results if there are no results
    if (Number(totalResults) === 0) {
        return (
            <NoResultsView
                primaryPageTitle={primaryPageTitle}
                endpoints={endpoints}
                canonicalUrl={canonicalUrl}
                dispatch={dispatch}
                brandedSite={brandedSite}
            />
        )
    }

    return (
        <div className="sps2-serp">
            <BreadCrumbList breadcrumbsList={breadCrumb} />
            <ResultsTitle text={primaryPageTitle} />
            <Tabs.TabsContainer
                ref={tabsRef}
                defaultActive={0}
                scrollIntoView={true}
                alignment={'center'}
            >
                <Tabs.TabItem
                    title={capProducts + ' ' + wrapInParenthesis(productsTotal)}
                    onClick={() => {
                        setTab(PRODUCTS_TAB)
                        TealiumTag(getTealiumObj('mds-tabs Click: Products'))
                    }}
                    className={productsTotal > 0 ? '' : 'sps2-serp--hidden'}
                >
                    {productsTotal > 0 && (
                        <ProductsView
                            aggregations={aggregations}
                            breadBox={breadBox}
                            canonicalUrl={canonicalUrl}
                            dispatch={dispatch}
                            endpoints={endpoints}
                            items={items}
                            total={total}
                            loading={loading}
                            currentTab={PRODUCTS_TAB}
                            allEndpoints={endpoints}
                            breadCrumb={breadCrumb}
                            queryId={queryId}
                        />
                    )}
                </Tabs.TabItem>

                <Tabs.TabItem
                    title={
                        industryApplications + ' ' + wrapInParenthesis(webContentTotal)
                    }
                    onClick={() => {
                        setTab(INDUSTRY_APPLICATIONS_TAB)
                        TealiumTag(getTealiumObj('mds-tabs Click: Industry Applications'))
                    }}
                    className={webContentTotal > 0 ? '' : 'sps2-serp--hidden'}
                >
                    {webContentData?.items && (
                        <WebContentView
                            total={webContentData.total}
                            items={webContentData.items}
                            endpoints={webContentData.endpoints}
                            dispatch={dispatch}
                            queryId={queryId}
                        />
                    )}
                </Tabs.TabItem>

                <Tabs.TabItem
                    title={regulatory + ' ' + wrapInParenthesis(regulatoryTotal)}
                    onClick={() => {
                        setTab(REGULATORY_TAB)
                        TealiumTag(getTealiumObj('mds-tabs Click: Regulatory'))
                    }}
                    className={regulatoryTotal > 0 ? '' : 'sps2-serp--hidden'}
                >
                    {regulatoryData?.items && (
                        <RegulatoryView
                            total={regulatoryData.total}
                            items={regulatoryData.items}
                            endpoints={regulatoryData.endpoints}
                            dispatch={dispatch}
                            canonicalUrl={canonicalUrl}
                            breadBox={regulatoryData.breadBox}
                            aggregations={regulatoryData.aggregations}
                            loading={loading}
                            allEndpoints={endpoints}
                            currentTab={REGULATORY_TAB}
                            queryId={queryId}
                        />
                    )}
                </Tabs.TabItem>

                <Tabs.TabItem
                    title={resources + ' ' + wrapInParenthesis(resourcesTotal)}
                    onClick={() => {
                        setTab(RESOURCES_TAB)
                        TealiumTag(getTealiumObj('mds-tabs Click: Resources'))
                    }}
                    className={resourcesTotal > 0 ? '' : 'sps2-serp--hidden'}
                >
                    {resourcesData?.mediaLinks && (
                        <ResourcesView
                            aggregations={resourcesData.aggregations}
                            breadBox={resourcesData.breadBox ?? []}
                            canonicalUrl={canonicalUrl}
                            dispatch={dispatch}
                            endpoints={resourcesData.endpoints}
                            items={resourcesData.mediaLinks}
                            total={resourcesData.total}
                            loading={loading}
                            allEndpoints={endpoints}
                            currentTab={RESOURCES_TAB}
                            queryId={queryId}
                        />
                    )}
                </Tabs.TabItem>
                {!brandedSite.length && (
                    <Tabs.TabItem
                        title={allResults}
                        onClick={() => {
                            setTab(ALL_RESULTS_TAB)
                            TealiumTag(getTealiumObj('mds-tabs Click: All Results'))
                        }}
                        className={totalResults > 0 ? '' : 'sps2-serp--hidden'}
                    >
                        <AllResultsView
                            allTabData={allTabData}
                            analyticsUrl={endpoints.analyticsUrl}
                            setActiveTab={(index) => {
                                tabsRef.current.jumpTo(index)
                                setTab(index)
                            }}
                            queryId={queryId}
                        />
                    </Tabs.TabItem>
                )}
            </Tabs.TabsContainer>
        </div>
    )
}

export default SERP
