import React, { useReducer, createContext } from 'react'
import reducer from './ReducerService.jsx'

const StateContext = createContext({})

/**
 * StateProvider returns the current status of the state.
 * @param {object} children the objects to be renderd with the data.
 * @param {state} initialState the initial state
 * @returns {context} in order to handle or get the current state.
 */
const StateProvider = ({ children, ...initialState }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const {
        aggregations,
        breadBox,
        breadCrumb,
        canonicalUrl,
        endpoints,
        fuzeRequest,
        greater,
        items,
        loading,
        primaryPageTitle,
        query,
        queryId,
        regulatoryData,
        resourcesData,
        secondaryPageTitle,
        total,
        webContentData,
        richContent,
        locale,
        // PDP TODO: create different state object to manage PDP, SERP, PCP
        isParent,
        productDetails,
        benefits,
        resources,
        resourcesMore,
        relatedProducts,
        replacement,
        moreOptions,
        productOptions,
        loadingProductOptions,
        matchingProductOptions,
        media,
        mediaMore,
        mediaMoreCount,
        mediaOffset,
        classificationAttributes,
        sds,
        tds,
        ctaInfo,
        disclaimerInfo,
        contactUs,
        boughtTogether,
        boughtTogetherMore,
        boughtTogetherAggs,
        boughtTogetherType,
        instructions,
        relatedProductsAttributes,
        selectedOptions,
        errorMessage,
        detailsZone,
        oneProductSelection,
        postPurchaseZone,
        pdpPageOffset,
        displayController,
        highlightSelectors,
        focusViewProductOptions,
        resourcesAggs,
        resourcesType,
        activeMedia,
        brandedSite,
        highlightRichContent,
        childrenSeo,
        errors,
        requiredProducts,
        requiredProductsMore
    } = state
    return (
        <StateContext.Provider
            value={{
                aggregations,
                breadBox,
                breadCrumb,
                canonicalUrl,
                dispatch,
                endpoints,
                fuzeRequest,
                greater,
                items,
                loading,
                primaryPageTitle,
                query,
                queryId,
                secondaryPageTitle,
                regulatoryData,
                resourcesData,
                total,
                webContentData,
                richContent,
                locale,
                // PDP
                isParent,
                productDetails,
                benefits,
                resources,
                resourcesMore,
                relatedProducts,
                replacement,
                moreOptions,
                oneProductSelection,
                productOptions,
                loadingProductOptions,
                matchingProductOptions,
                media,
                mediaMore,
                mediaMoreCount,
                mediaOffset,
                classificationAttributes,
                sds,
                tds,
                ctaInfo,
                disclaimerInfo,
                contactUs,
                boughtTogether,
                boughtTogetherMore,
                boughtTogetherAggs,
                boughtTogetherType,
                instructions,
                relatedProductsAttributes,
                selectedOptions,
                errorMessage,
                detailsZone,
                postPurchaseZone,
                pdpPageOffset,
                displayController,
                highlightSelectors,
                focusViewProductOptions,
                resourcesAggs,
                resourcesType,
                activeMedia,
                brandedSite,
                highlightRichContent,
                childrenSeo,
                errors,
                requiredProducts,
                requiredProductsMore
            }}
        >
            {children}
        </StateContext.Provider>
    )
}

export { StateContext, StateProvider }
