import axios from 'axios'
import { getLocale } from './UtilityService.jsx'
import { getUserCookie } from './BrowserService.jsx'
import { localHostCORSFix, getClientFromEndpoint } from './URLService.jsx'
import { MESSAGE_US_ENDPOINT } from '../constants/PDPConstants.js'

/**
 * postSignalData makes a post request to an endpoint of a payload including the position of a clicked item
 * @param {string} endpoint The endpoint to post the request to
 * @param {number} position The position of the item clicked
 * @param {string} signalType This represent the type of signal (interation that trigger this request)
 * @param {string} stockNumber This is the value for the stockNumber
 */
const postSignalData = (endpoint, position, signalType, stockNumber, queryId) => {
    const service = getClientFromEndpoint(endpoint)
    const TRACKINGAPI = axios.create({
        baseURL: endpoint,
        headers: { 'Content-Type': 'application/json' }
    })

    const reqPayLoad = {
        position: position,
        mmmId: stockNumber,
        userCookie: getUserCookie(),
        signalType: signalType,
        service: service,
        locale: getLocale(),
        queryId: queryId
    }

    const data = JSON.stringify(reqPayLoad)

    try {
        TRACKINGAPI.post('', data)
    } catch (error) {
        console.log('Error on post tracking data: ' + error)
    }
}

/**
 * getRequest fetches and returns a promise of a response
 * @param {string} endpoint The endpoint that axios should perform a get request on]
 * @param {string} brandedSite The value that flags country and branded catalog
 */
const getRequest = (endpoint) => {
    endpoint = localHostCORSFix(endpoint)
    const brandedSiteInfo = window.__INITIAL_DATA?.brandedSite
    const pageUniqueName = window.__INITIAL_DATA?.uniqueName
    if (brandedSiteInfo?.length) {
        const pageUniqueNameheader = `snapsPageUniqueName=CORP_SNAPS_BRAND_${brandedSiteInfo[0]}_${brandedSiteInfo[1]}`
        return axios.get(endpoint, {
            headers: { 'snaps-override_7': pageUniqueNameheader }
        })
    } else if (pageUniqueName) {
        const pageUniqueNameheader = `snapsPageUniqueName=${pageUniqueName}`
        return axios.get(endpoint, {
            headers: { 'snaps-override_7': pageUniqueNameheader }
        })
    }
    return axios.get(endpoint)
}

/**
 * getTabsTotal fetches and returns a promise of a response with all the endpoints
 * @param {string} resEP The endpoint for the Resources Tab
 * @param {string} webContEP The endpoint for the WebContent, items for Industry Applications & News
 * @param {string} regulatoryEP The endpoint for the Regulatory Tab
 */
const getTabsTotal = (resEP, webContEP, regulatoryEP) => {
    const useNull = () => false
    const tabsData = []
    const endpointsToCall = [false, false, false]
    if (resEP) {
        endpointsToCall[0] = getRequest(resEP + '&size=0').catch(useNull)
    }
    if (webContEP) {
        endpointsToCall[1] = getRequest(webContEP + '&size=0').catch(useNull)
    }
    if (regulatoryEP) {
        endpointsToCall[2] = getRequest(regulatoryEP + '&size=0').catch(useNull)
    }
    return axios.all(endpointsToCall).then(
        axios.spread((...allData) => {
            for (const tab of allData) {
                tabsData.push(!tab ? {} : tab.data)
            }
            return tabsData
        })
    )
}

/**
 * postSupportForm returns a promise of the response to post the support form
 * @param {*} data data obj to be sent to endpoint
 * @returns promise
 */
const postSupportForm = (data) => {
    return axios.post(MESSAGE_US_ENDPOINT, data, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    })
}

export { getRequest, postSignalData, getTabsTotal, postSupportForm }
